import {
    onlyAlphanumeric,
} from '@/_validations/validacionEspeciales'

import { 
    required, 
    minLength,
    maxLength,
    decimal } from "vuelidate/lib/validators";
    

export default () => {
    return {
            UnNumber:{
                required,
                minLength: minLength(5),
                maxLength: maxLength(250),
            },
            UnNumberDsEs:{
                required,
                minLength: minLength(5),
                maxLength: maxLength(250),
            },
            UnNumberDsEn:{
                required,
                minLength: minLength(5),
                maxLength: maxLength(250),
            },
            ImdgId:{
                required,
            },
            ImdgClassId:{
                required,
            },

    }
}

